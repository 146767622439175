import { PayPalButtons } from '@paypal/react-paypal-js';

const PaypalButton = ({ onOrder, onError, amount, type, payee }) => {
	const fee = (amount * 0.2).toFixed(2);

	return (
		<PayPalButtons
			// fundingSource={type}
			style={{
				label: 'pay',
				tagline: false,
				layout: 'vertical',
			}}
			createOrder={(order, actions) => {
				return actions.order.create({
					intent: "CAPTURE",
					application_context: {
						shipping_preference: "NO_SHIPPING",
						user_action: "PAY_NOW"
					},
					purchase_units: [{
						amount: {
							currency_code: "USD",
							value: amount
						},
						payee: {
							merchant_id: payee
						},
						payment_instruction: {
							disbursement_mode: "DELAYED",
							platform_fees: [{
								amount: {
									currency_code: "USD",
									value: fee
								}
							}]
						}
					}]
				})
			}}
			onApprove={(approve, actions) => {
				return actions.order.capture().then((capture) => {
					onOrder(capture.purchase_units[0]?.payments?.captures[0]?.id);
				});
			}}
			onError={console.log} // Todo: Catch error
		/>
	);
};

export default PaypalButton;
