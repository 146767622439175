import axios from 'axios';
import { makeAutoObservable } from 'mobx';

class Auth {
	showModal = false;
	isAuth = false;
	isSocketAuth = false;
	token = null;
	accessToPlatform = false;

	user = {
		id: null,
		type: null,
		name: null,
		surname: null,
		avatar: null,
		paypal: null,
		isProvider: false,
		isCustomer: false,
		access: false,
	};

	profile = {
		stringsToFill: [],
		backgroundCheck: null,
	}


	constructor() {
		makeAutoObservable(this);
		const savedToken = localStorage.getItem('token');
		const userType = localStorage.getItem('userType');
		const accessToPlatform = localStorage.getItem('accessToPlatform');
		if (savedToken) {
			this.authorize(savedToken, userType, accessToPlatform);
		}
	}

	*fetchUserInfo() {
		try {
			const { data } = yield axios.get('/auth/user');
			if (data.isKicked) {
				this.unauthorize();
				return;
			}
			this.user = {
				id: data.id,
				type: data.type,
				name: data.name,
				surname: data.surname,
				avatar: data.avatar,
				paypal: data.paypal,
				isProvider: data.type === 'provider',
				isCustomer: data.type === 'customer',
				access: data.access.access,
			};
			this.profile = {
				stringsToFill: data.access.fields,
				backgroundCheck: data.access.backgroundCheck
			}
		} catch (e) {
			if (e.response?.status === 401) {
				this.unauthorize();
			}
		}
	}

	changeNames(name, surname) {
		if (this.user.name !== name) this.user.name = name;
		if (this.user.surname !== surname) this.user.surname = surname;
	}

	changeAvatar(avatar) {
		this.user.avatar = avatar;
	}

	toggleModal(show) {
		this.showModal = show ? show : !this.showModal;
	}

	authorize(token, type, accessToPlatform) {
		this.showModal = false;
		this.token = token;
		this.isAuth = true;
		this.accessToPlatform = type === 'customer' ? true : accessToPlatform;
		this.user.type = type;
		this.user.isCustomer = type === 'customer';
		this.user.isProvider = type === 'provider';
		localStorage.setItem('token', token);
		localStorage.setItem('userType', type);
		localStorage.setItem('accessToPlatform', accessToPlatform);
		axios.defaults.headers.authorization = `Bearer ${token}`;
	}

	socketAuhorize(status) {
		this.isSocketAuth = status;
	}

	unauthorize() {
		this.showModal = false;
		this.token = null;
		this.isAuth = false;
		this.accessToPlatform = false;
		localStorage.removeItem('token');
		localStorage.removeItem('userType');
		localStorage.removeItem('accessToPlatform');
		delete axios.defaults.headers.authorization;

		this.user = {
			id: null,
			name: null,
			surname: null,
			type: null,
			isProvider: false,
			isCustomer: false,
		};
	}
}

const auth = new Auth();
export default auth;
