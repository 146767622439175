import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Completed from 'components/Base/Completed';
import BaseTabs from 'components/Base/BaseTabs';
import BaseModal from 'components/Base/BaseModal';
import MessageModal from 'components/Modals/MessageModal';
import Loader from 'components/Base/Loader';

import IconMessage from './img/message.png';
import IconPaid from './img/paid.svg';
import { getAvatar } from 'utils/helpers';
import PaypalButton from 'components/Paypal/PaypalButton';


const OrderBids = () => {
	const { id: jobId } = useParams();
	const [job, setJob] = useState({});
	const [fetching, setFetching] = useState(true);
	const [tabs, setTabs] = useState([
		{ value: 'pending', name: 'Pending', count: 0 },
		{ value: 'rejected', name: 'Rejected', count: 0 },
		{ value: 'hired', name: 'Hired', count: 0 },
	])
	const [currentTab, setCurrentTab] = useState(tabs[0].value);
	const [completed, setCompleted] = useState(false);

	const [modalBid, setModalBid] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isPaid, setIsPaid] = useState(false);

	const navigate = useNavigate();

	const bids = useMemo(() => {
		return job.bids?.filter((bid) => bid.status === currentTab) || [];
	}, [currentTab, job]);

	const onPaid = useCallback(
		(id) => {
			axios.patch(`/jobs/${jobId}/paid`, { payment_id: id }).then(() => {
				setIsPaid(true);
			});
		},
		[jobId]
	);

	const fetchBids = useCallback(() => {
		setFetching(true);

		axios
			.get(`/jobs/${jobId}/bids`)
			.then(({ data }) => {
				setJob(data);
				setTabs((prev) => {
					return prev.map((item) => {
						item.count = data.bids.filter((job) => job.status === item.value).length
						return { ...item }
					})
				})
			})
			.catch((e) => {
				navigate('/');
			})
			.finally(() => {
				setFetching(false);
			});
	}, [jobId, navigate]);

	const acceptBid = useCallback(() => {
		axios
			.patch(`/jobs/${jobId}/bids/${modalBid._id}/accept`)
			.then(() => {
				setCompleted(true);
				setShowModal(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [jobId, modalBid]);

	const declineBid = useCallback(() => {
		axios
			.patch(`/jobs/${jobId}/bids/${modalBid._id}/decline`)
			.then(() => {
				fetchBids();
				setShowModal(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [jobId, fetchBids, modalBid]);

	useEffect(() => {
		setJob({});
		fetchBids();
	}, [fetchBids]);

	return fetching ? (
		<div className="page bids">
			<h1>Job Bids</h1>
			<Loader />
		</div>
	) : (
		<div className="page bids">
			<h1>Job Bids</h1>
			<p className="for">
				For: <Link to={`/jobs/${jobId}`}>{job.title}</Link>
			</p>

			<BaseModal
				show={showModal === 'accept'}
				onClose={() => setShowModal(false)}
				className="center accept"
			>
				<h2>Accept this bid?</h2>
				<p>
					<b>${modalBid?.price || job.price}</b> by {modalBid?.provider?.name}{' '}
					{modalBid?.provider?.surname}
				</p>

				{isPaid ? (
					<div className="paid">
						<img src={IconPaid} alt="Success paid" />
						<span>Successfully paid</span>
					</div>
				) : (
					<PaypalButton
						amount={modalBid?.price || job.price}
						payee={modalBid?.provider?.paypal}
						type="paypal"
						onOrder={onPaid}
					/>
				)}

				<p className="small">
					In order to accept a bid for a job, you must pay in advance for the
					work. The money will be transferred to the provider only after you
					mark the work as completed.
				</p>

				<button onClick={acceptBid} className="base-button" disabled={!isPaid}>
					Yes, accept
				</button>
			</BaseModal>

			<BaseModal
				show={showModal === 'decline'}
				onClose={() => setShowModal(false)}
				className="center"
			>
				<h2>Decline this bid?</h2>
				<p>
					<b>${modalBid?.price || job.price}</b> by {modalBid?.provider?.name}{' '}
					{modalBid?.provider?.surname}
				</p>
				<button onClick={declineBid} className="base-button">
					Yes, decline
				</button>
			</BaseModal>

			<MessageModal
				show={showModal === 'message'}
				onClose={() => setShowModal(false)}
				user={modalBid?.provider}
			/>

			<BaseTabs
				tabs={tabs}
				onTab={setCurrentTab}
				current={currentTab}
				sticky
			>
				{!bids.length && (
					<div className="empty">
						<span>{currentTab}</span> list is empty
					</div>
				)}

				{bids.map((bid, index) => (
					<div className="bid" key={index}>
						<div className="avatar">
							<img src={getAvatar(bid.provider.avatar)} alt="Avatar" />
						</div>

						<div className="content">
							<Link className="title" to={`/providers/${bid.provider._id}`}>
								{bid.provider.name} {bid.provider.surname}
							</Link>
							<div className="info">
								<div className="item">
									Date:
									<b>
										{moment(bid.date_create).format('DD MMM. YYYY - HH:mm')}
									</b>
								</div>
								<div className="item">
									Requested Price:
									<b>
										{bid.price
											? `Suggested $${bid.price}`
											: `Fixed $${job.price}`}
									</b>
								</div>
							</div>

							<div className="more">
								<div className="message">{bid.message}</div>
								<div className="buttons">
									{bid.status === 'pending' && job.status === 'active' && (
										<button
											className="btn"
											onClick={() => {
												setModalBid(bid);
												setShowModal('accept');
												setIsPaid(false);
											}}
										>
											Accept
										</button>
									)}

									{bid.status === 'pending' && job.status === 'active' && (
										<button
											className="btn bordered"
											onClick={() => {
												setModalBid(bid);
												setShowModal('decline');
											}}
										>
											Decline
										</button>
									)}

									{bid.status === 'rejected' && (
										<button className="btn bordered" disabled>
											Rejected
										</button>
									)}

									{bid.status === 'hired' && (
										<button className="btn bordered" disabled>
											Hired
										</button>
									)}

									<button
										className="btn bordered message"
										onClick={() => {
											setModalBid(bid);
											setShowModal('message');
										}}
									>
										<img src={IconMessage} alt="Message" />
									</button>
								</div>
							</div>
						</div>
					</div>
				))}
			</BaseTabs>

			<Completed
				show={completed}
				setShow={setCompleted}
				redirect={`/jobs/my`}
			/>
		</div>
	);
};

export default OrderBids;
