import { useRef } from 'react';

export const useTimeout = () => {
	const timer = useRef();

	return (func, time) => {
		clearTimeout(timer.current);
		timer.current = setTimeout(func, time);
	};
};
