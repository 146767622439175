import { useState } from 'react';
import BaseTabs from 'components/Base/BaseTabs';

import Review from './Review';

const Tabs = [
	{ value: 'Main' },
	{ value: 'Reviews' },
]

const ProviderDetails = ({ user }) => {
	const [tab, setTab] = useState(Tabs[0].value);

	return (
		<div className="about">
			<BaseTabs tabs={Tabs} current={tab} onTab={setTab}>
				{tab === Tabs[0].value && (
					<div className="resume">
						<h2>Resume</h2>
						<p>{user.resume || 'Nothing here yet'}</p>

						<h2>Languages</h2>
						<p>
							Primary: <b>{user.languages.primary}</b>
							{user.languages.secondary && (
								<>
									<br />
									Secondary: <b>{user.languages.secondary}</b>
								</>
							)}
						</p>

						{user.cost?.length > 0 && (
							<>
								<h2>Services</h2>
								{user.cost?.map((cost, index) => (
									<div className="cost" key={index}>
										<div className="title">{cost.title}</div>
										<div className="dots">&nbsp;</div>
									</div>
								))}
							</>
						)}
					</div>
				)}

				{tab === Tabs[1].value && (
					<div className="list reviews">
						{!user.reviews.length && (
							<div className="empty">Nothing here yet</div>
						)}

						{user.reviews.map((review) => (
							<Review data={review} key={review._id} />
						))}
					</div>
				)}
			</BaseTabs>
		</div>
	);
};

export default ProviderDetails;
