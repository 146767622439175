import axios from 'axios';
import moment from 'moment';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../store';

import OrderOptions from './OrderOptions';
import BaseRadio from 'components/Base/BaseRadio';
import Completed from 'components/Base/Completed';
import Loader from 'components/Base/Loader';

// import IconBack from "./img/back.svg";
import IconLock from 'pages/Home/img/lock.svg';
import IconUnlock from 'pages/Home/img/unlock.svg';
import PaypalConnectButton from 'components/Paypal/PaypalConnectButton';
import BaseModal from 'components/Base/BaseModal';

const JobsDetails = () => {
	const { id: jobId } = useParams();
	const [job, setJob] = useState({});
	const [fetching, setFetching] = useState(true);
	const [orderShow, setOrderShow] = useState(false);
	const [isSuggest, setIsSuggest] = useState(false);
	const [bidSended, setBidSended] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const [paypalLoading, setPaypalLoading] = useState(false);

	const navigate = useNavigate();
	const store = useStore();

	const { isProvider, isCustomer, paypal } = store.auth.user;

	useEffect(() => {
		setJob({});
		setFetching(true);
		axios
			.get(`/jobs/${jobId}`)
			.then(({ data }) => {
				setJob(data);
			})
			.catch((e) => {
				navigate('/');
			})
			.finally(() => {
				setFetching(false);
			});
	}, [jobId, navigate]);

	const onBid = useCallback((bid) => {
		setOrderShow(false);
		setBidSended(true);
		setJob((prev) => ({ ...prev, bid }));
	}, []);

	const rescheduleJob = useCallback(() => {
		axios
			.patch(`/jobs/${jobId}/reschedule`)
			.then(({ data }) => {
				setJob(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [jobId]);

	const acceptReschedule = useCallback(() => {
		axios
			.patch(`/jobs/${jobId}/reschedule/accept`)
			.then(({ data }) => {
				setJob(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [jobId]);

	const cancelJob = useCallback(() => {
		axios
			.patch(`/jobs/${jobId}/cancel`)
			.then(({ data }) => {
				setJob(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [jobId]);

	const isAfter24Hours = useMemo(() => {
		if (job.date) {
			return moment(`${job?.date?.split("T")[0]}T${job.time?.startTime}`).isAfter(moment().add(24, 'hours'));
		}
	}, [job]);


	return fetching ? (
		<Loader />
	) : (
		<>
			<div className="job page">
				<div className="left">
					<div className="header">
						<div className="title">
							{job.title}
							{/* <div className="back">
							<img src={IconBack} alt="Back" />
						</div> */}
						</div>
						<div className="user">
							<Link className="name" to={`/customers/${job.creator._id}`}>
								{job.creator?.name} {job.creator?.surname}
							</Link>
							<div className="location">{job.creator?.city}</div>
						</div>
					</div>

					<div className="description">
						<div className="label">Description</div>
						<div className="content">{job.description}</div>
					</div>

					<div className="tags">
						<div className="label">Tags</div>
						{job.tags.map((tag) => (
							<div className="item" key={tag}>
								{tag}
							</div>
						))}
					</div>
				</div>

				<div className="right">
					<div className="box">
						<div className="title">When</div>
						<div className="info">
							{moment(job.date).format('DD MMMM, YYYY')} <br />
							Part of day: {job.time.startTime} - {job.time.endTime}
						</div>
						{isAfter24Hours && <>
							{isProvider &&
								<>
									{
										(job.status === "working" || job.status === "pending") ?
											<button
												className='btn'
												disabled={job.status !== "working"}
												onClick={rescheduleJob}
											>
												{job.status === "working" ? "Ask for reschedule" : "Waiting for customer to reschedule"}
											</button>
											:
											<>
												{job.status === "pending_acceptance" &&
													<div
														className="btn"
														onClick={() => setShowModal(true)}
													>
														Show detail of reschedule
													</div>
												}
											</>
									}
								</>
							}

							{isCustomer &&
								<Link className='btn'
									disabled={!(job.status === "working" || job.status === "pending")}
									to={`/jobs/${job._id}/reschedule`}
								>
									{(job.status === "working" || job.status === "pending") ? "Reschedule" : "Waiting for provider"}
								</Link>
							}
						</>
						}
					</div>

					<div className="box">
						<div className="title">Where</div>
						<div className="info">
							USA, {job.location?.city}, {job.location?.zip}
							<br />
							{job.location?.street}
						</div>
					</div>

					{((job.bid && isProvider) || isCustomer) && (
						<div className="box applied">
							<div className="title">
								<span>
									{isProvider ? 'You applied to this job' : 'Job budget'}
								</span>
								<div className="price">
									<img
										src={job.suggest_price ? IconUnlock : IconLock}
										alt="Suggested price"
										className={classNames({ unlock: job.suggest_price })}
									/>
									<span>${job.price}</span>
								</div>
							</div>
							{isProvider ? (
								<div className="message">{job.bid?.message}</div>
							) : (
								<div className="info">Only providers can add a bid</div>
							)}
						</div>
					)}

					{job.status === 'active' && isProvider && !job.bid && (
						<div className="box">
							<div className="title">Budget</div>
							<div className="info budget">
								<div className="item">
									<BaseRadio
										text={'$' + job.price}
										name="budget"
										className="price"
										onChange={() => setIsSuggest(false)}
										checked={!isSuggest}
									/>
									{/* <div className="desc">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry.
								</div> */}
								</div>

								{job.suggest_price && (
									<div className="item">
										<BaseRadio
											text={'Suggest price'}
											name="budget"
											className="price"
											onChange={() => setIsSuggest(true)}
											checked={isSuggest}
										/>
										{/* <div className="desc">
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry.
									</div> */}
									</div>
								)}
							</div>

							<button
								className="apply btn"
								onClick={() => setOrderShow(true)}
								disabled={!paypal}
							>
								Apply
							</button>

							{!paypal && (
								<div className="paypal-connect">
									<p>
										You must connect your PayPal account before applying for a job
									</p>

									<PaypalConnectButton />

									<Loader show={paypalLoading} sticky={false} />
								</div>
							)}
							{/* <div className="message btn">Message {job.creator?.name} {job.creator?.surname[0]}.</div> */}
						</div>
					)}
				</div>

				<OrderOptions
					job={job}
					isSuggest={isSuggest}
					isShow={orderShow}
					onHide={() => setOrderShow(false)}
					onBid={onBid}
				/>

				<Completed show={bidSended} setShow={setBidSended} />
			</div>

			<BaseModal
				show={showModal}
				onClose={() => setShowModal(false)}
				className="modal-popup"
			>
				<div className="info">
					<div className="about center-text">
						<h2>
							New date and time of work <br />
						</h2>
						{moment(job.date).format('DD MMMM, YYYY')}<br />
						Start time {job.time.startTime} - End time {job.time.endTime}
					</div>
				</div>

				<div className="buttons">
					<div
						className="btn"
						onClick={() => {
							acceptReschedule();
							setShowModal(false);
						}}
					>
						Accept
					</div>
					<div
						className="btn red"
						onClick={() => {
							cancelJob();
							setShowModal(false);
						}}
					>
						Decline
					</div>
				</div>
			</BaseModal>
		</>
	);
};

export default observer(JobsDetails);
