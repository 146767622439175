import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { generateCSV, parseError } from 'utils/helpers';
import { USStateCodes } from 'utils/constants';
import { useStore } from '../../store';

import BaseEdit from 'components/Base/BaseEdit';
import Completed from 'components/Base/Completed';
import Loader from 'components/Base/Loader';
import { observer } from 'mobx-react-lite';
import PhoneInput from 'react-phone-input-2';
import PaypalConnectButton from 'components/Paypal/PaypalConnectButton';
import { useGoogleLogin } from '@react-oauth/google';
import BaseCheckbox from 'components/Base/BaseCheckbox';
import classNames from 'classnames';

const TabGeneral = () => {
	const avatarPrev = useRef();
	const avatarInput = useRef();
	const [avatarChanged, setAvatarChanged] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSavedField, setIsSavedField] = useState({});
	const [error, setError] = useState({});
	const [generalInfo, setGeneralInfo] = useState({
		name: '',
		surname: '',
		location: {
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zipCode: '',
			country: '',
			countryCode: '',
		},
		about: '',
		phone: '',
		autoCalendarEvent: false,
		isGoogleConnected: false,
	});
	const [prevGeneralInfo, setPrevGeneralInfo] = useState({});
	const store = useStore();

	useEffect(() => {
		axios.get('/profile/general').then(({ data }) => {
			setGeneralInfo(data);
			setPrevGeneralInfo(data);
		});
	}, []);


	// Only on first page loading
	useEffect(() => {
		if (store.auth.user.avatar && !avatarPrev.current) {
			avatarPrev.current = store.auth.user.avatar;
		}
	}, [store.auth.user.avatar]);

	// Only once on unmount
	useEffect(() => {
		return () => {
			store.auth.changeAvatar(avatarPrev.current);
		};
	}, [store.auth]);

	const onAvatarChange = useCallback(
		(event) => {
			if (event.target.files.length) {
				const file = event.target.files[0];
				const image = URL.createObjectURL(file);
				store.auth.changeAvatar(image);
				setAvatarChanged(true);
			}
		},
		[store.auth]
	);

	const saveAvatar = useCallback(() => {
		const file = avatarInput.current.files[0];
		const form = new FormData();
		form.append('avatar', file);

		setIsLoading(true);

		axios
			.patch('/profile/avatar', form)
			.then(({ data }) => {
				avatarPrev.current = data.avatar;
				avatarInput.current.value = '';
				store.auth.changeAvatar(data.avatar);
				setAvatarChanged(false);
				setIsSaved(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [store.auth]);

	const createGoogleToken = useGoogleLogin({
		onSuccess: credentialResponse => {
			createToken(credentialResponse.code)
		},

		flow: 'auth-code',
		onError: (e) => console.log('Google error', e),
		cookiePolicy: 'single_host_origin',
		responseType: 'code',
		accessType: 'offline',
		scope: 'openid email profile https://www.googleapis.com/auth/calendar'
	});

	const createToken = (token) => {
		axios.post('/profile/connectcalendar', { token })
	}

	const toggleGoogleNotification = (e) => {
		setGeneralInfo(prevForm => ({
			...prevForm,
			autoCalendarEvent: e.target.checked,
		}));
		saveChanges("autoCalendarEvent", e.target.checked);
	};



	const downloadCSV = () => {
		axios.get('/profile/jobhistory').then(({ data }) => {
			const output = generateCSV(data);
			const blob = new Blob([output], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', 'MyJobHistory.csv');
			a.click();
		});
	};


	const compareChanges = (e) => {
		const { name, value } = e.target;
		if (prevGeneralInfo.location[name] === value || prevGeneralInfo[name] === value) return
		saveChanges(name, value);
	}

	const comparePhoneNumber = (e) => {
		if (prevGeneralInfo.phone === e.target.value) return
		saveChanges("phone", e.target.value);
	}


	const saveChanges = (name, value) => {
		setError({});
		setIsLoading(true);
		let field = name;
		if (generalInfo[name] === undefined) {
			field = `location.${name}`;
		}

		axios
			.patch('/profile/general', { field, value })
			.then(({ data }) => {
				if (field === "name" || field === "surname") {
					store.auth.changeNames(generalInfo.name, generalInfo.surname);
				}

				setIsSavedField({ [field]: true })

				setTimeout(() => {
					setIsSavedField({});
				}, 900);
			}).catch((error) => {
				setError(parseError(error));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}


	return (
		<div className="edit-tab general">
			<div className="base-label">Avatar</div>
			<div className="avatar">
				<input
					type="file"
					className="base-input"
					ref={avatarInput}
					onChange={onAvatarChange}
				/>
				<button
					className="base-button upload"
					disabled={!avatarChanged}
					onClick={saveAvatar}
				>
					Upload
				</button>
			</div>

			<div className="base-label">Name</div>
			<div className='input-container'>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField.name })}
					name="name"
					value={generalInfo.name}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, name: e.target.value }))}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField.name })}
				>
					Saved
				</span>
			</div>

			<div className='input-container'>
				<div className="base-label">Surname</div>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField.surname })}
					name='surname'
					value={generalInfo.surname}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, surname: e.target.value }))}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField.surname })}
				>
					Saved
				</span>
			</div>

			<div className='input-container'>
				<div className="base-label">Address Line 1</div>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField['location.addressLine1'] })}
					name='addressLine1'
					value={generalInfo.location.addressLine1}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, addressLine1: e.target.value } }))}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField['location.addressLine1'] })}
				>
					Saved
				</span>
			</div>

			<div className='input-container'>
				<div className="base-label">Address Line 2</div>
				<input
					type="text"
					className={classNames('base-input', { saved: isSavedField['location.addressLine2'] })}
					name='addressLine2'
					value={generalInfo.location.addressLine2}
					onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, addressLine2: e.target.value } }))}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField['location.addressLine2'] })}
				>
					Saved
				</span>
			</div>

			<div className="flex">
				<div>
					<div className='input-container'>
						<div className="base-label">City</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['location.city'] })}
							name='city'
							value={generalInfo.location.city}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, city: e.target.value } }))}
							onBlur={(e) => compareChanges(e)}
						/>
						<span
							className={classNames("saved-message", { show: isSavedField['location.city'] })}
						>
							Saved
						</span>
					</div>
				</div>

				<div>
					<div className='input-container'>
						<div className="base-label">State</div>
						<select
							id="state"
							name="state"
							className={classNames('base-input', { saved: isSavedField['location.state'] })}
							value={generalInfo.location.state}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, state: e.target.value } }))}
							onBlur={(e) => compareChanges(e)}
						>
							<option value=""> -- Select a state --</option>
							{
								Object.entries(/*USStateCodes*/{ MA: 'MA' }).map(([stateCode, stateName]) => (
									<option key={stateCode} value={stateCode}>{stateName}</option>
								))
							}
						</select>
						<span
							className={classNames("saved-message", { show: isSavedField['location.state'] })}
						>
							Saved
						</span>
					</div>
				</div>

				<div>
					<div className='input-container'>
						<div className="base-label">Zip Code</div>
						<input
							type="text"
							className={classNames('base-input', { saved: isSavedField['location.zipCode'] })}
							name="zipCode"
							value={generalInfo.location.zipCode}
							onInput={(e) => setGeneralInfo(prevForm => ({ ...prevForm, location: { ...prevForm.location, zipCode: e.target.value } }))}
							onBlur={(e) => compareChanges(e)}
						/>
						<span
							className={classNames('saved-message', { show: isSavedField['location.zipCode'] })}
						>
							Saved
						</span>
					</div>
				</div>
			</div>

			{store.auth.user.type === 'customer' && (
				<div className='input-container'>
					<div className="base-label" >Phone number</div>
					<PhoneInput
						className={classNames('phone-input', { save: isSavedField.phone })}
						country={'us'}
						specialLabel=''
						value={generalInfo.phone?.toString()}
						onChange={(phone) => setGeneralInfo(prevForm => ({ ...prevForm, phone: phone }))}
						onBlur={(e) => comparePhoneNumber(e)}
					/>
					<span
						className={classNames("saved-message", { show: isSavedField['phone'] })}
					>
						Saved
					</span>
				</div>
			)}


			<div className="base-label">Sync google calendar</div>
			{!generalInfo.isGoogleConnected &&
				<>
					<button className='btn' onClick={createGoogleToken}>Connect Google account</button>
					<br />
				</>
			}

			<div className='google-event'>
				{generalInfo.autoCalendarEvent !== undefined &&
					(<BaseCheckbox
						onChange={toggleGoogleNotification}
						checked={generalInfo.autoCalendarEvent}
						text={"Google calendar notifications"}
					>
					</BaseCheckbox>
					)}
			</div>

			<button className='base-button csv' onClick={downloadCSV}>Download job history (CSV)</button>

			{/* <div className='input-container save'> */}
			<div className={classNames('input-container', { saved: isSavedField.about })}>
				<div className="base-label">About me</div>
				<BaseEdit
					name={'about'}
					value={generalInfo.about}
					onChange={(about) => setGeneralInfo(prevForm => ({ ...prevForm, about: about }))}
					onBlur={(e) => compareChanges(e)}
				/>
				<span
					className={classNames("saved-message", { show: isSavedField.about })}
				>
					Saved
				</span>
			</div>

			{store.auth.user.type === 'customer' && (
				<p className="after-about">
					Your profile is your way of presenting yourself to potential caregivers
					that are going to work with you. This will help ensure you find the
					right match for your needs.
				</p>
			)}
			<div className="bottom">
				{error.message && (
					<div className="error">
						<b>{error.type}:</b> {error.message}
					</div>
				)}
			</div>

			<Loader show={isLoading} />
			<Completed show={isSaved} setShow={setIsSaved} />
		</div>
	);
};

export default observer(TabGeneral);
